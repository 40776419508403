import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./Downloads.module.scss";
import DownloadsRow from "./DonwloadsRow.js";
import ModalWindow from "../../common/ModalWindow/ModalWindow";
import NativeReleaseNotes1919 from "./Files/NativeReleaseNotes-1.9.19.txt";
import NativeReleaseNotes201 from "./Files/NativeReleaseNotes-2.0.1.txt";
import NativeReleaseNotes220 from "./Files/NativeReleaseNotes-2.2.0.txt";
import NativeReleaseNotes230 from "./Files/NativeReleaseNotes-2.3.0.txt";
import NativeReleaseNotes235 from "./Files/NativeReleaseNotes-2.3.5.txt";
import NativeReleaseNotes242 from "./Files/NativeReleaseNotes-2.4.2.txt";
import NativeReleaseNotes246 from "./Files/NativeReleaseNotes-2.4.6.txt";
import CordovaReleaseNotes236 from "./Files/CordovaReleaseNotes-2.3.6.txt";
import RNReleaseNotes201Build2 from "./Files/RNReleaseNotes-2.0.1-build2.txt";
import DownloadSDKModal from "../../blocks/DownloadSDKModal";
import { setAgreementDate } from "../../store/AC/usersManage";
import { getSDKFile } from "../../store/AC/download";
import { isUndefined } from "../../utils/helpers";
import toast from "react-hot-toast";

class Downloads extends Component {
  constructor(props) {
    super(props);

    const filesNativeSDK = [
      {
        filename: "250203-SDK-Aila(REV2.4.6).zip",
        version: "2.4.6",
        releaseNotes: NativeReleaseNotes246,
        supportedOS: ["iOS 15.6 - 18.3", "iPadOS 15.6 - 18.3"],
        supportedDevices: [
          "9.7 iPad Gen6",
          "10.2 iPad Gen7, Gen8, Gen9",
          "10.9 iPad Gen10",
          "12.9 iPad Pro Gen4, Gen5, Gen6",
          "13-inch iPad Pro M4",
          "13-inch iPad Air M2",
          "iPad Mini Gen6, Gen7",
          "iPod Touch Gen7",
          "iPhone 10",
          "iPhone 12",
          "iPhone 14, Pro",
          "iPhone 15 Pro, Pro Max",
          "iPhone 16, Pro Max",
          "iPhone SE 2, 3",
        ],
      },
      {
        filename: "241001 - SDK-Aila(REV2.4.2).zip",
        version: "2.4.2",
        releaseNotes: NativeReleaseNotes242,
        supportedOS: ["iOS 15.6 - 18.0", "iPadOS 15.6 - 18.0"],
        supportedDevices: [
          "iPad Pro 13.0 M4",
          "iPad Air 13.0 M2",
          "iPad Pro 12.9 Gen 4-6",
          "iPad 10.9 Gen 10",
          " iPad 10.2 Gen 7-9",
          "iPad 9.7 Gen 6",
          "iPhone 15",
          "iPhone 14",
          "iPhone 11",
          "iPhone SE 2-3",
          "iPod Touch Gen 7",
        ],
      },
      {
        filename: "240710-SDK-Aila(REV2.3.5).zip",
        version: "2.3.5",
        releaseNotes: NativeReleaseNotes235,
        supportedOS: ["iOS 15.6 - 17.5.1", "iPadOS 15.6 - 17.5.1"],
        supportedDevices: [
          "iPad Air 13.0 M2",
          "iPad Pro 12.9 Gen 4-6",
          "iPad 10.9 Gen 10",
          "iPad 10.2 Gen 7-9",
          "iPad 9.7 Gen 6",
          "iPhone 15",
          "iPhone 14",
          "iPhone 11",
          "iPhone SE 2-3",
          "iPod Touch Gen 7",
        ],
      },
      {
        filename: "230822-SDK-Aila-(REV2.2.0).zip",
        version: "2.2.0",
        releaseNotes: NativeReleaseNotes220,
      },
      {
        filename: "221219-SDK-Aila-(REV2.0.1).zip",
        version: "2.0.1",
        releaseNotes: NativeReleaseNotes201,
      },
      {
        filename: "220112-SDK-PadlocScan-(REV1.9.19).zip",
        version: "1.9.19",
        releaseNotes: NativeReleaseNotes1919,
      },
    ];

    const filesScannerSDK = [
      {
        filename_example: "Cordova-Plugin-w-example-v2.4.6.zip",
        filename: "Cordova-Plugin-v2.4.6.zip",
        version: "2.4.6",
        name: "Cordova Plugin",
        releaseNotes: NativeReleaseNotes246,
        supportedOS: ["iOS 15.6 - 18.3", "iPadOS 15.6 - 18.3"],
        supportedDevices: [
          "9.7 iPad Gen6",
          "10.2 iPad Gen7, Gen8, Gen9",
          "10.9 iPad Gen10",
          "12.9 iPad Pro Gen4, Gen5, Gen6",
          "13-inch iPad Pro M4",
          "13-inch iPad Air M2",
          "iPad Mini Gen6, Gen7",
          "iPod Touch Gen7",
          "iPhone 10",
          "iPhone 12",
          "iPhone 14, Pro",
          "iPhone 15 Pro, Pro Max",
          "iPhone 16, Pro Max",
          "iPhone SE 2, 3",
        ],
      },
      {
        filename_example: "Cordova-Plugin-w-example-v2.4.2.zip",
        filename: "Cordova-Plugin-v2.4.2.zip",
        version: "2.4.2",
        name: "Cordova Plugin",
        releaseNotes: NativeReleaseNotes242,
        supportedOS: ["iOS 15.6 - 18.0", "iPadOS 15.6 - 18.0"],
        supportedDevices: [
          "iPad Pro 13.0 M4",
          "iPad Air 13.0 M2",
          "iPad Pro 12.9 Gen 4-6",
          "iPad 10.9 Gen 10",
          " iPad 10.2 Gen 7-9",
          "iPad 9.7 Gen 6",
          "iPhone 15",
          "iPhone 14",
          "iPhone 11",
          "iPhone SE 2-3",
          "iPod Touch Gen 7",
        ],
      },
      {
        filename_example: "Cordova-Plugin-w-example-v2.3.6.zip",
        filename: "Cordova-Plugin-v2.3.6.zip",
        version: "2.3.6",
        name: "Cordova Plugin",
        releaseNotes: CordovaReleaseNotes236,
        supportedOS: ["iOS 15.6 - 17.5.1", "iPadOS 15.6 - 17.5.1"],
        supportedDevices: [
          "iPad Air 13.0 M2",
          "iPad Pro 12.9 Gen 4-6",
          "iPad 10.9 Gen 10",
          "iPad 10.2 Gen 7-9",
          "iPad 9.7 Gen 6",
          "iPhone 15",
          "iPhone 14",
          "iPhone 11",
          "iPhone SE 2-3",
          "iPod Touch Gen 7",
        ],
      },
      {
        filename_example: "Cordova-Plugin-w-example-v2.3.5.zip",
        filename: "Cordova-Plugin-v2.3.5.zip",
        version: "2.3.5",
        name: "Cordova Plugin",
        releaseNotes: NativeReleaseNotes235,
        supportedOS: ["iOS 15.6 - 17.5.1", "iPadOS 15.6 - 17.5.1"],
        supportedDevices: [
          "iPad Air 13.0 M2",
          "iPad Pro 12.9 Gen 4-6",
          "iPad 10.9 Gen 10",
          "iPad 10.2 Gen 7-9",
          "iPad 9.7 Gen 6",
          "iPhone 15",
          "iPhone 14",
          "iPhone 11",
          "iPhone SE 2-3",
          "iPod Touch Gen 7",
        ],
      },
      {
        filename_example: "Cordova-Plugin-v2.2.0-w-example.zip",
        filename: "Cordova-Plugin-v2.2.0.zip",
        name: "Cordova Plugin",
        version: "2.2.0",
        releaseNotes: NativeReleaseNotes220,
        hasNpm: false,
        pkg: "cordova-plugin-aila",
      },
      {
        filename: "Cordova-Plugin-v.2.0.1.zip",
        version: "2.0.1",
        releaseNotes: NativeReleaseNotes201,
        hasNpm: false,
        pkg: "cordova-plugin-aila",
      },
      {
        filename: "Cordova-Plugin-v.1.9.19.zip",
        version: "1.9.19",
        releaseNotes: NativeReleaseNotes1919,
      },
    ];

    const filesReactNativeSDK = [
      {
        filename_example: "ReactNative-Plugin-v2.4.6-w-example.zip",
        filename: "ailatech-react-native-aila-sdk-2.4.6.tgz",
        name: "React Native Plugin",
        version: "2.4.6",
        releaseNotes: NativeReleaseNotes246,
        supportedOS: ["iOS 15.6 - 18.3", "iPadOS 15.6 - 18.3"],
        supportedDevices: [
          "9.7 iPad Gen6",
          "10.2 iPad Gen7, Gen8, Gen9",
          "10.9 iPad Gen10",
          "12.9 iPad Pro Gen4, Gen5, Gen6",
          "13-inch iPad Pro M4",
          "13-inch iPad Air M2",
          "iPad Mini Gen6, Gen7",
          "iPod Touch Gen7",
          "iPhone 10",
          "iPhone 12",
          "iPhone 14, Pro",
          "iPhone 15 Pro, Pro Max",
          "iPhone 16, Pro Max",
          "iPhone SE 2, 3",
        ],
      },
      {
        filename_example: "ReactNative-Plugin-v2.4.2-w-example.zip",
        filename: "ailatech-react-native-aila-sdk-2.4.2.tgz",
        name: "React Native Plugin",
        version: "2.4.2",
        releaseNotes: NativeReleaseNotes242,
        supportedOS: ["iOS 15.6 - 18.0", "iPadOS 15.6 - 18.0"],
        supportedDevices: [
          "iPad Pro 13.0 M4",
          "iPad Air 13.0 M2",
          "iPad Pro 12.9 Gen 4-6",
          "iPad 10.9 Gen 10",
          " iPad 10.2 Gen 7-9",
          "iPad 9.7 Gen 6",
          "iPhone 15",
          "iPhone 14",
          "iPhone 11",
          "iPhone SE 2-3",
          "iPod Touch Gen 7",
        ],
      },
      {
        filename_example: "ReactNative-Plugin-v2.3.5-w-example.zip",
        filename: "ailatech-react-native-aila-sdk-2.3.5.tgz",
        name: "React Native Plugin",
        version: "2.3.5",
        releaseNotes: NativeReleaseNotes235,
        supportedOS: ["iOS 15.6 - 17.5.1", "iPadOS 15.6 - 17.5.1"],
        supportedDevices: [
          "iPad Air 13.0 M2",
          "iPad Pro 12.9 Gen 4-6",
          "iPad 10.9 Gen 10",
          "iPad 10.2 Gen 7-9",
          "iPad 9.7 Gen 6",
          "iPhone 15",
          "iPhone 14",
          "iPhone 11",
          "iPhone SE 2-3",
          "iPod Touch Gen 7",
        ],
      },
      {
        filename_example: "ReactNative-Plugin-v2.2.0-w-example.zip",
        filename: "ailatech-react-native-aila-sdk-2.2.0.tgz",
        name: "React Native Plugin",
        version: "2.2.0",
        releaseNotes: NativeReleaseNotes220,
        hasNpm: false,
        pkg: "react-native-aila-sdk",
      },
      {
        filename: "React-Native-Plugin-v2.0.1-build.2.zip",
        version: "2.0.1-build.2",
        releaseNotes: RNReleaseNotes201Build2,
        hasNpm: false,
        pkg: "react-native-aila-sdk",
      },
      {
        filename: "ReactNative-Plugin-v.2.0.1.zip",
        version: "2.0.1",
        releaseNotes: NativeReleaseNotes201,
        hasNpm: true,
        pkg: "react-native-aila-sdk",
      },
      {
        filename: "ReactNative-Plugin-v.1.9.19.tgz",
        version: "1.9.19",
        releaseNotes: NativeReleaseNotes1919,
      },
    ];

    this.state = {
      SDKLicenseAgreed: this.props.SDKLicenseAgreed,
      modal: {
        open: false,
        content: "",
      },
      downloadModal: {
        open: false,
        filename: "",
        loading: false,
      },
      maxFiles: 5,
      nativeSDK: {
        files: filesNativeSDK,
        show: false,
      },
      scannerSDK: {
        files: filesScannerSDK,
        show: false,
      },
      reactSDK: {
        files: filesReactNativeSDK,
        show: false,
      },
    };
  }

  showFiles = (table) => {
    const { downloadModal } = this.state;
    return table.files.map((file, i) => {
      if (i < this.state.maxFiles || table.show) {
        return (
          <DownloadsRow
            key={"row-" + i}
            id={i}
            item={file}
            openModal={this.openModal}
            openDownloadModal={this.openDownloadModal}
            downloadModal={downloadModal}
          />
        );
      }
    });
  };

  showMoreVersions = (table) => {
    const click = () => {
      table.show = true;
      this.setState({ state: this.state });
    };

    if (table.files.length > this.state.maxFiles) {
      return (
        <tr>
          <td colSpan="4" className={styles.lastLine}>
            <span onClick={click}>Show More Versions</span>
          </td>
        </tr>
      );
    }
  };

  openDownloadModal = (filename) => {
    //On Request the modal should not be opened, just go ahead and download the file
    if (!this.state.downloadModal.open) {
      this.setState({ downloadModal: { open: false, filename } }, () =>
        this.handleSDKDownload()
      );
    }
  };

  handleSDKDownload = () => {
    const toastId = toast.loading("Downloading SDK...");
    if (this.state.downloadModal.loading) return;
    const downloadModal = { ...this.state.downloadModal };
    downloadModal.loading = true;
    this.setState({ downloadModal });
    this.props.getSDKFile(this.state.downloadModal.filename, (res) => {
      downloadModal.loading = false;
      if (res instanceof Error) {
        toast.error("Error! SDK download failed.", { id: toastId });
        this.setState({ downloadModal });
        return;
      }
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", this.state.downloadModal.filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      this.setState({ downloadModal });
      if (!this.props.SDKLicenseAgreed) {
        this.props.setAgreementDate();
      }
      toast.success("Success! SDK downloaded successfully.", { id: toastId });
      this.closeModal();
    });
  };

  openModal = (content) => {
    if (!this.state.modal.open) {
      this.setState({ modal: { open: true, content: content } });
    }
  };

  closeModal = () => {
    if (this.state.modal.open || this.state.downloadModal.open) {
      this.setState({
        modal: { open: false, content: "" },
        downloadModal: { open: false, download: "" },
      });
    }
  };

  render() {
    const { modal, downloadModal } = this.state;
    const { errorDownload } = this.props;
    return (
      <div className={styles.downloadsPage + " row"}>
        <p className="col-lg-11 offset-lg-1 big-size-font">Downloads</p>
        {/*<p className={styles.pageDescription + ' col-lg-11 offset-lg-1 col-md-12'}>You can access your license keys in the <Link to="/dashboard">Dashboard</Link> section.</p>*/}
        <div className="col-lg-11 offset-lg-1 col-md-12">
          <h2 className="middle-size-font">Native SDK for iOS</h2>
          <table className={styles.downloadsTables + " table table-hover"}>
            <tbody>
              {this.showFiles(this.state.nativeSDK)}
              {this.showMoreVersions(this.state.nativeSDK)}
            </tbody>
          </table>

          <h2 className="middle-size-font">Cordova Plugin</h2>
          <table className={styles.downloadsTables + " table table-hover"}>
            <tbody>
              {this.showFiles(this.state.scannerSDK)}
              {this.showMoreVersions(this.state.scannerSDK)}
            </tbody>
          </table>

          <h2 className="middle-size-font">React Native Module</h2>
          <table className={styles.downloadsTables + " table table-hover"}>
            <tbody>
              {this.showFiles(this.state.reactSDK)}
              {this.showMoreVersions(this.state.reactSDK)}
            </tbody>
          </table>
        </div>

        <DownloadSDKModal
          open={downloadModal.open}
          onClose={this.closeModal}
          loading={downloadModal.loading}
          error={errorDownload}
          onClickDownload={this.handleSDKDownload}
        />

        <ModalWindow open={modal.open} onClose={this.closeModal}>
          <h2>Release Notes</h2>
          <object
            type="text/html"
            aria-label=""
            data={modal.content}
            className={styles.modalContent}
          />
        </ModalWindow>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    fullUserInfo: { agreementDate },
  } = state.authentification;
  const { error } = state.download;
  const SDKLicenseAgreed =
    !isUndefined(agreementDate) || state.usersManage.SDKLicenseAgreed;
  return {
    SDKLicenseAgreed,
    errorDownload: error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setAgreementDate: (cb) => dispatch(setAgreementDate(cb)),
  getSDKFile: (file, cb) => dispatch(getSDKFile(file, cb)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Downloads);
